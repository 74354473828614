import React, { useState, useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaValue, setCaptchaValue] = useState('');
  const [captchaValid, setCaptchaValid] = useState(false);
  const [isValid, setIsValid] = useState();
  const [isSubmitted, setIsSubmitted] = useState();

  useEffect(() => {
    const areFieldsFilled = email !== '' && name !== '' && message !== '';
    setShowCaptcha(areFieldsFilled);
  }, [email, name, message]);

  useEffect(() => {
    setCaptchaValid(captchaValue === '7');
  }, [captchaValue]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  }

  const handleCaptchaChange = (e) => {
    setCaptchaValue(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (captchaValid) {
      emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      ).then((result) => {
        console.log(result.text);
        setIsValid(true);
        setIsSubmitted(true);
      }, (error) => {
        console.error(error.text);
      });
      form.current.reset();
      setEmail('');
      setName('');
      setMessage('');
      console.log('Formulaire envoyé !');
    } else {
      alert('Veuillez résoudre le captcha correctement !');
    }
  }

  return (
    <form ref={form} onSubmit={handleSubmit}>
      <input type="email" name="from_email" placeholder="E-mail" value={email} onChange={handleEmailChange} required />
      <input type="text" name="to_name" placeholder="Nom / Prénom" value={name} onChange={handleNameChange} required />
      <textarea cols="30" rows="10" placeholder="Message..." name="message" value={message} onChange={handleMessageChange} required />
      {showCaptcha && (
        <input type="text" placeholder="5 + 2 = ?" onChange={handleCaptchaChange} required />
      )}
      {
        captchaValid && (
          <>
            <div className={`submit-button ${isValid ? "valid" : ""}`}>
              <button type="submit" disabled={isSubmitted}>
                <p>ENVOYER</p>
                <svg className="send" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path d="M452.1 49L52.3 265.3c-6 3.3-5.6 12.1.6 14.9l68.2 25.7c4 1.5 7.2 4.5 9 8.4l53 109.1c1 4.8 9.9 6.1 10 1.2l-8.1-90.2c.5-6.7 3-13 7.3-18.2l207.3-203.1c1.2-1.2 2.9-1.6 4.5-1.3 3.4.8 4.8 4.9 2.6 7.6L228 338c-4 6-6 11-7 18l-10.7 77.9c.9 6.8 6.2 9.4 10.5 3.3l38.5-45.2c2.6-3.7 7.7-4.5 11.3-1.9l99.2 72.3c4.7 3.5 11.4.9 12.6-4.9L463.8 58c1.5-6.8-5.6-12.3-11.7-9z" fill="currentColor" /></svg>
                <svg className="valid-send" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m.5 8.55l2.73 3.51a1 1 0 0 0 .78.39a1 1 0 0 0 .78-.36L13.5 1.55" /></svg>
              </button>
              <p className="submit-text">Votre message à bien était envoyer, nous vous recontacterons dans les plus bref délais !</p>
            </div>
          </>
        )
      }
    </form >
  );
};

export default Contact;
