import info from "../fixture/information.json";
import { motion } from "framer-motion";

const Work = () => {
  return (
    <div id="works">
      <section className="title">
        <div>
          <h3>NOS RÉALISATION</h3>
          <hr />
        </div>
      </section>

      <div className="cards-works">
        {info.work.map((work, i) => (
          <motion.div whileHover={{ scale: 1.1 }} className="card" key={i}>
            <img key={i} alt={work.image.alt} src={work.image.src} />
            <article className="text-card">
              <h5>{work.title}</h5>
            </article>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Work;
