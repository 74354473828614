import Layout from "./components/layout";
import Services from "./components/services";
import Map from "./components/map";
import Advantage from "./components/advantage";
import Works from "./components/works";
import Loading from "./components/loading";
import React, { useState, useEffect } from 'react';
function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Temps du chargement, vous pouvez l'ajuster selon vos besoins

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
          {isLoading ? (
        <Loading />
      ) : (
      <Layout>
        <Services />
        <Map />
        <Advantage />
        <Works/>
      </Layout>
         )}
    </>
  );
}

export default App;
