const Map = () => {
    return (
        <section id="map">

            <section className="title">
                <div>
                    <h3>ZONE D'INTERVENTIONS</h3>
                    <hr />
                </div>
            </section>
            <div className="map-img">
                <div className="img-border">
                    <img src="./assets/map.webp" alt="map de la localisation où nous intervenons." />
                </div>
            </div>

        </section>
    )
}

export default Map;