import React, { useState } from "react";
import Contact from "./contact";
import { animated, useSpring } from "@react-spring/web";

const Layout = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleLinkClick = (sectionId) => {
    scrollToSection(sectionId);
    handleModalToggle(); // Fermer la modale après avoir cliqué sur un lien
  };

  const AnimatedHeading = () => {
    const styles = useSpring({
      from: { opacity: 0, transform: "translateX(100%)" },
      to: { opacity: 1, transform: "translateX(0)" },
      config: { duration: 1000 },
    });

    return <animated.h2 style={styles}>Fred Débarrassage</animated.h2>;
  };

  return (
    <>
      <header id="header">
        <nav>
          <h1 onClick={() => scrollToSection("header")}>
            <img alt="logo du débarras" src="./assets/logo.png" />
          </h1>
          <ul>
            <li>
              <a href="tel:+33770891896" title="Appelez-nous dès maintenant !">
                07 70 89 18 96
              </a>
            </li>
            <li onClick={() => scrollToSection("services")}>Nos Services</li>
            <li onClick={() => scrollToSection("advantage")}>Nos Avantages</li>
            <li onClick={() => scrollToSection("works")}>Nos Réalisations</li>
            <li onClick={() => scrollToSection("contact")}>Nous Contactez</li>
          </ul>
          <button className="modal-button" onClick={handleModalToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M4 18q-.425 0-.712-.288T3 17q0-.425.288-.712T4 16h16q.425 0 .713.288T21 17q0 .425-.288.713T20 18zm0-5q-.425 0-.712-.288T3 12q0-.425.288-.712T4 11h16q.425 0 .713.288T21 12q0 .425-.288.713T20 13zm0-5q-.425 0-.712-.288T3 7q0-.425.288-.712T4 6h16q.425 0 .713.288T21 7q0 .425-.288.713T20 8z"
              />
            </svg>
          </button>
        </nav>
        {isModalOpen && (
          <div className="modal">
            <div className="close-modal">
              <button onClick={() => handleLinkClick("header")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M20 20L4 4m16 0L4 20"
                  />
                </svg>
              </button>
            </div>
            <ul>
              <li onClick={() => handleLinkClick("services")}>Nos Services</li>
              <li onClick={() => handleLinkClick("advantage")}>
                Nos Avantages
              </li>
              <li onClick={() => handleLinkClick("contact")}>Nous Contactez</li>
              <li onClick={() => handleLinkClick("works")}>Nos réalisations</li>
            </ul>
          </div>
        )}

        <section className="home">
          <div className="border-img">
            <img src="./assets/profile-picture.webp" alt="profil camion" />
          </div>
          <div className="home-title">
            <div>
              <AnimatedHeading />
              <hr />
            </div>
            <h3>
              Débarras maison, appartement, cave, entrepôt, particulier
              <br></br>ou professionnel sur Grenoble et ses alentours.
            </h3>
            <p>Entreprise familial depuis 2011</p>
          </div>
        </section>
      </header>
      <main>{children}</main>
      <footer>
        <div className="footer">
          <article className="footer-text">
            <section className="text-block">
              <h4>A PROPOS </h4>
              <p>Fréderic Roux</p>
              <p>Grenoble & alentours</p>
              <p>Entreprise familial depuis 2011 </p>
              <p>Siret : 51815332500015</p>
              {/* <p className="paragraph-block">Entreprise spécialisé dans le débarrassage et le dépôt /<br></br> locationde bennes.</p> */}
            </section>
            {/* <section className="text-block">
              <h4>ZONE D'INTERVENTION</h4>
              <p>Grenoble & alentours</p>
            </section> */}
            <section className="text-block">
              <h4>CONTACT</h4>
              <div className="footer-block-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M16 18H7V4h9m-4.5 18a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5m4-21h-8A2.5 2.5 0 0 0 5 3.5v17A2.5 2.5 0 0 0 7.5 23h8a2.5 2.5 0 0 0 2.5-2.5v-17A2.5 2.5 0 0 0 15.5 1z"
                    fill="currentColor"
                  />
                </svg>
                <a
                  href="tel:+33770891896"
                  title="Appelez-nous dès maintenant !"
                >
                  07 70 89 18 96{" "}
                </a>
              </div>
              <div className="footer-block-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 36 36"
                >
                  {" "}
                  <path
                    fill="currentColor"
                    d="M32 6H4a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2m-1.54 22H5.66l7-7.24l-1.44-1.39L4 26.84V9.52l12.43 12.37a2 2 0 0 0 2.82 0L32 9.21v17.5l-7.36-7.36l-1.41 1.41ZM5.31 8h25.07L17.84 20.47Z"
                    className="clr-i-outline clr-i-outline-path-1"
                  />
                  <path fill="none" d="M0 0h36v36H0z" />
                </svg>
                <a
                  href="mailto:fredrouxgrenoble@gmail.com"
                  title="N'hesitez pas a m'écrire !"
                >
                  fredrouxgrenoble@gmail.com
                </a>
              </div>
            </section>
            {/* <section className="mentions">
              <div onClick={() => scrollToSection("header")}>
                {/* <img
                  className="logo-footer"
                  alt="logo de l'entreprise"
                  src="./assets/logo.png"
                /> */}
              {/* </div>
              <p>MENTIONS LÉGALES</p>
              <p>TOUS DROITS RÉSERVER </p>
            </section> */} 
          </article>
          <div id="contact" className="contact-footer">
            <Contact />
            <section className="mentions">
            <p>MENTIONS LÉGALES</p>
              <p>TOUS DROITS RÉSERVER </p>
         </section>
            <p className="copyright"><a href="https://www.jv-design-et-tech.fr/">JV design & tech - COPYRIGHT © 2024 </a></p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
