import { motion } from "framer-motion";
import { animated, useSpring } from "@react-spring/web";
import { useIntersection } from "react-use";
import React from "react";
import info from "../fixture/information.json";

const Services = () => {
  const AnimatedElement = ({ children }) => {
    const ref = React.useRef(null);
    const intersection = useIntersection(ref, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    const springProps = useSpring({
      opacity: intersection && intersection.intersectionRatio >= 0.1 ? 1 : 0,
      transform:
        intersection && intersection.intersectionRatio >= 0.1
          ? "translateY(0)"
          : "translateY(40px)",
      config: { tension: 40, friction: 20 },
    });

    return (
      <animated.div ref={ref} style={springProps}>
        {children}
      </animated.div>
    );
  };

  return (
    <section id="services">
      <section className="title">
        <div>
          <AnimatedElement>
            <h3>NOS SERVICES</h3>
            <hr />
          </AnimatedElement>
        </div>
      </section>

      <div className="cards-services">
        {info.services.map((service, i) => (
          <AnimatedElement key={i}>
            <motion.div whileHover={{ scale: 1.1 }} className="card">
              {service.img.map((img, i) => (
                <img key={i} alt={img.alt} src={img.src} />
              ))}
              <article className="text-card">
                <h5>{service.title}</h5>
                <p>{service.description}</p>
              </article>
            </motion.div>
          </AnimatedElement>
        ))}
      </div>
    </section>
  );
};

export default Services;
