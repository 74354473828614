import React, { useState, useEffect } from 'react';


const Loading = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots === '...' ? '' : prevDots + '.'));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loading">
        <img src="./assets/logo.png" alt="logo du site"/>
      <h1>Loading{dots}</h1>
    </div>
  );
};

export default Loading;
