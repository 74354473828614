import info from "../fixture/information.json"
import { motion } from "framer-motion";
import { animated, useSpring } from "@react-spring/web";
import { useIntersection } from "react-use";
import React from "react";
const Advantage = () => {

    const AnimatedElement = ({ children }) => {
        const ref = React.useRef(null);
        const intersection = useIntersection(ref, {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        });
    
        const springProps = useSpring({
          opacity: intersection && intersection.intersectionRatio >= 0.1 ? 1 : 0,
          transform:
            intersection && intersection.intersectionRatio >= 0.1
              ? "translateY(100px)"
              : "translateY(0px)",
          config: { tension: 40, friction: 20 },
        });
    
        return (
          <animated.div ref={ref} style={springProps}>
            {children}
          </animated.div>
        );
      };
    
    return (

        <section id="advantage">

            <section className="title">
                <div>
                <AnimatedElement>
                    <h3>NOS AVANTAGES</h3>
                    <hr />
                    </AnimatedElement>
                </div>
            </section>

            <div className="cards-content">
                {info.avantages.map((avantage, i) => (
                      <AnimatedElement>
                    <motion.section key={i} whileHover={{scale:1.1}} className="card">
                        <div dangerouslySetInnerHTML={{ __html: avantage.img }} />
                        <h5>{avantage.title}</h5>
                        <p>{avantage.description}</p>
                    </motion.section>
                     </AnimatedElement>
                ))}
            </div>
        </section>
    )
}

export default Advantage;